/**
 * ----------------------------
 * * Declare vars
 * ----------------------------
 */

// buttons
let translateButton = $(`[data-translate]`);
let translateButtonEnglish = $(`[data-translate='en']`);
let translateButtonSpanish = $(`[data-translate='es']`);

// content
let translateContent = $(`[data-translate-content]`);
let translateContentEnglish = $(`[data-translate-content='en']`);
let translateContentSpanish = $(`[data-translate-content='es']`);

/**
 * ----------------------------
 * * Classes
 * ----------------------------
 */
let activeClass = 'bg-red text-white border-red';
let removeClass = 'hover:text-red focus:text-red';

/**
 * ----------------------------
 * * Page Load
 * ----------------------------
 */

// * Hide content by default
translateContent.hide();

// * Get URL params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

// * title, keyword
if(urlParams.get('lang')) {
    let languageParam = urlParams.get('lang');
    if(languageParam == 'es') {
        translateButtonSpanish.addClass(activeClass).removeClass(removeClass);
        translateContentSpanish.show();
    } else {
        translateButtonEnglish.addClass(activeClass).removeClass(removeClass);
        translateContentEnglish.show();
    }
} else {
    translateButtonEnglish.addClass(activeClass).removeClass(removeClass);
    translateContentEnglish.show();
}

// Events
translateButton.on('click keypress', function() {

    // * get language value
    let language = $(this).data('translate'); 

    // * hide all content
    translateContent.hide(); 

    // * set classes for buttons back to default
    translateButton.removeClass(activeClass).addClass(removeClass);

    // * show content that belongs to language value
    $(`[data-translate-content='${language}']`).show();
    $(`[data-translate='${language}']`).addClass(activeClass).removeClass(removeClass);

    let pageUrl = location.protocol + '//' + location.host + location.pathname;
    pageUrl += "?lang=" + language;
    return history.pushState(null, '', pageUrl); 

});