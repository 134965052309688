// import AnimateScroll from './components/AnimateScroll';
import Dialog from './components/Dialog';
import './components/SlickCarousel';
import './components/Accordion';
import './components/CounterUp';
import './components/Translate';
// import './components/Navbar';

Dialog.init();

// document.addEventListener('DOMContentLoaded', () => {
//   AnimateScroll.init();
// });
