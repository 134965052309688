/*
* --------------------------------------------------
* Accordion
*
* Use [data-toggle="#element"] on a button
* Use [data-target="#element"] on dropdown element
* --------------------------------------------------
*/

// hide all data targets/dropdowns by default
$('[data-target]:not(.data-toggle-active)').hide();

// find target on click
$('[data-toggle]').on('click keypress', function(){

    // toggle class for clicked element
    if( $(this).hasClass('data-toggle-active') ) {
        $(this).removeClass('data-toggle-active');
    } else {
        $('[data-toggle]').removeClass('data-toggle-active');
        $(this).addClass('data-toggle-active');
    }

    // console.log($(this).data('hide'));
    if($(this).data('hide') == true) {
        $(this).hide();
    }

    // get target element
    let target = $(this).data('toggle');

    // get target element's group if it exists
    let group = $(`[data-target='${target}']`).data('group');

    if($(this).data('toggle-fast') == true) {
        // hide all group items EXCEPT the current data target element
        $('[data-group]').not($(`[data-target='${target}']`)).hide();
        // toggle current data target element
        $(`[data-target='${target}']`).toggle();
    } else {
        // hide all group items EXCEPT the current data target element
        $('[data-group]').not($(`[data-target='${target}']`)).slideUp();
        // toggle current data target element
        $(`[data-target='${target}']`).slideToggle();
    }
    
});
