/*
 * Slick (Home Hero)
 */

// name vars
let slickSliderHero = $('[data-slick-hero]');
let slides = $('[data-slick-hero] div');

if (slickSliderHero.length) {
    // console.log('active');

    // add animate css default class
    slides.addClass('animate__animated');

    // get random slide number
    // let random = Math.floor(Math.random() * slides.length); // returns a random integer from 0 to 9

    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        cssEase: 'linear',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        variableWidth: true,
        draggable: false,
    };

    // init slider
    slickSliderHero.slick(options);

    // animate before slide changes
    slickSliderHero.on('beforeChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
    ) {
        slides.removeClass('animate__bounceInRight');
        slides.eq(nextSlide).addClass('animate__bounceInRight');
    });
}

/*
 * Slick (Home Hero Mobile)
 */

let slickSliderHeroMobile = $('[data-slick-hero-mobile]');
if (slickSliderHeroMobile.length) {
    let slidesHeroMobile = $('[data-slick-hero-mobile] div');
    // add animate css default class
    slidesHeroMobile.addClass('animate__animated');

    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        // slidesToShow: 4,
        variableWidth: true,
        centerMode: true,
        centerPadding: '20px',
    };

    // init slider
    slickSliderHeroMobile.slick(options);

    // animate before slide changes
    slickSliderHeroMobile.on('beforeChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
    ) {
        slidesHeroMobile.removeClass('animate__bounceInRight');
        slidesHeroMobile.eq(nextSlide).addClass('animate__bounceInRight');
    });
}

/*
 * Slick (Stories)
 */

let slickSliderStories = $('[data-slick-stories]');
if (slickSliderStories.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    autoplay: true,
                    infinite: true,
                },
            },
        ],
    };

    // init slider
    slickSliderStories.slick(options);
}

/*
 * Slick (General)
 */

let slickSlider = $('[data-slick]');
if (slickSlider.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 4,
        variableWidth: true,
        prevArrow: $('[data-slick-prev]'),
        nextArrow: $('[data-slick-next]'),
    };

    // init slider
    slickSlider.slick(options);
}

/*
 * Slick (Why)
 */

let slickSliderWhy = $('[data-slick-why]');
if (slickSliderWhy.length) {
  // slick slider options
  let options = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 4,
    variableWidth: true,
    prevArrow: $('[data-slick-why-prev]'),
    nextArrow: $('[data-slick-why-next]'),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // init slider
  slickSliderWhy.slick(options);
}

/*
 * Slick (Awards - Global)
 */

let slickSliderAwards = $('[data-slick-awards]');
if (slickSliderAwards.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        slidesToShow: 6,
        // centerMode: true, 
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    autoplay: true,
                    infinite: true,
                    // centerPadding: '70px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    // centerPadding: '50px',
                },
            },
        ],
    };

    // init slider
    slickSliderAwards.slick(options);
}

/*
 * Slick (Military Awards)
 */

let slickSliderMilitaryAwards = $('[data-slick-military-awards]');
if (slickSliderMilitaryAwards.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        slidesToShow: 7, 
        // centerMode: true, 
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    autoplay: true,
                    infinite: true,
                    // centerPadding: '70px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    infinite: true,
                    // centerMode: true,
                    // centerPadding: '50px',
                },
            },
        ],
    };

    // init slider
    slickSliderMilitaryAwards.slick(options);
}

/*
 * Slick (LOB Group)
 */

let slickSliderGroup = $('[data-slick-group]');
if (slickSliderGroup.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 3,
        variableWidth: true,
        centerMode: true,
    };

    // init slider
    slickSliderGroup.slick(options);
}

/*
 * Slick (D&I ERGs)
 */

let slickSliderErgs = $('[data-slick-ergs]');
if (slickSliderErgs.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false,
        speed: 400,
        fade: true,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        // adaptiveHeight: true,
        prevArrow: $('[data-slick-ergs-prev]'),
        nextArrow: $('[data-slick-ergs-next]'),
    };

    // init slider
    slickSliderErgs.slick(options);

    $('[data-slick-ergs-nav]').click(function() {
        $('.Ergs__icon').removeClass('Ergs__icon--active'); // * remove active class from all items
        let slidenum = $(this).data('slick-ergs-nav'); // * get slide value
        // alert(slidenum);
        $(this).addClass('Ergs__icon--active'); // * add active class to current slide
        // slickSliderErgs.slick('slickSetOption', 'speed', 0, true); // go to slide without seeing sliding effect
        slickSliderErgs.slick('slickGoTo', slidenum); // go to slidenum
        slickSliderErgs.slick('slickSetOption', 'speed', 400, true); // set speed back to normal for slide effect (in case user uses control arrows)
    });

    // mobile slider
    let slickSliderErgsMobile = $('[data-slick-ergs-mobile]');
    // slick slider options
    let optionsMobile = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        fade: false,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '50px',
    };
    slickSliderErgsMobile.slick(optionsMobile);
}

/*
 * Slick (D&I Content)
 */

let slickSliderContent = $('[data-slick-content]');
if (slickSliderContent.length) {
    // slick slider options
    let options = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        fade: false,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '50px',
    };
    slickSliderContent.slick(options);
}

/*
 * Slick (Carousel/General)
 */

let slickSliderCarousel = $('[data-slick-carousel]');
if (slickSliderCarousel.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('[data-slick-carousel-prev]'),
        nextArrow: $('[data-slick-carousel-next]'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // init slider
    slickSliderCarousel.slick(options);
}

/*
 * Slick (Blog Hero)
 */

let slickSliderBlogHero = $('[data-slick-blog-hero]');
if (slickSliderBlogHero.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 400,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
    };

    // init slider
    slickSliderBlogHero.slick(options);
}

/*
 * Slick (Blog Nav)
 */

let slickSliderBlogNav = $('[data-slick-blog-nav]');
if (slickSliderBlogNav.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    // init slider
    slickSliderBlogNav.slick(options);
}

/*
 * Slick (Words)
 */

let slickSliderWords = $('[data-slick-words]');
if (slickSliderWords.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 400,
        fade: true,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
    };

    // init slider
    slickSliderWords.slick(options);
}

/*
 * Slick (Hourly)
 */

let slickSliderHourly = $('[data-slick-hourly]');
if (slickSliderHourly.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('[data-slick-hourly-prev]'),
        nextArrow: $('[data-slick-hourly-next]'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // init slider
    slickSliderHourly.slick(options);
}

/*
 * Slick (Salaried)
 */

let slickSliderSalaried = $('[data-slick-salaried]');
if (slickSliderSalaried.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        prevArrow: $('[data-slick-salaried-prev]'),
        nextArrow: $('[data-slick-salaried-next]'),
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // init slider
    slickSliderSalaried.slick(options);
}

/*
 * Slick (Military Careers)
 */

let slickSliderMilitaryCareers = $(`[data-slick-military-careers]`);
if (slickSliderMilitaryCareers.length) {
    // slick slider options
    let options = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: false,
        speed: 400,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    autoplay: true,
                    infinite: true,
                },
            },
        ],
    };

    // init slider
    slickSliderMilitaryCareers.slick(options);

    // * carousel nav
    let careerItem = $('[data-slick-military-nav]');
    careerItem.on('click', function() {
        let slideId = $(this).data('slick-military-nav');
        careerItem.removeClass('active');
        $(this).addClass('active');
        slickSliderMilitaryCareers.slick('slickGoTo', slideId);
    });
}