const Dialog = {
    init: () => {
        let el = document.querySelectorAll('[data-dialog]');
        // console.log(el.length);
        for (let i = 0; i < el.length; i++) {
            el[i].addEventListener('click', (e) => {

                // * prevent default action
                e.preventDefault();
                // console.log(e.target.getAttribute('data-dialog'));

                // * get attribute and open/close
                let dialog = e.target.getAttribute('data-dialog') || e.target.parentElement.getAttribute('data-dialog');
                document.getElementById(dialog).classList.contains('Dialog--active') ? document.getElementById(dialog).classList.remove('Dialog--active') : document.getElementById(dialog).classList.add('Dialog--active');

                // * disable page scrolling while dialog is open
                if(document.getElementById(dialog).classList.contains('Dialog--active')) {
                    document.body.style.overflow = "hidden";
                } else {
                    document.body.style.overflow = "visible";
                }
            });
        }
    }
}

export default Dialog;